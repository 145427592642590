import { KeyboardEvent, KeyboardEventHandler } from "react";

export const blurOnEnter = <T extends HTMLInputElement>(
  callThroughHandler?: KeyboardEventHandler<T>
) => (e: KeyboardEvent<T>) => {
  if (e.key === "Enter") {
    e.preventDefault();
    e.currentTarget.blur();
  }
  if (callThroughHandler) {
    callThroughHandler(e);
  }
};
