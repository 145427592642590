import Cleave from "cleave.js/react";
import { isEmpty } from "lodash";
import { HTMLAttributes, ReactNode } from "react";
import { Controller } from "react-hook-form";

import { tw } from "../../tailwindcss-classnames";

import { WithLabel, WithLabelProps as WithLabelProperties } from "./WithLabel";
import { blurOnEnter } from "lib/blurOnEnter";

type AutoCompleteValues =
  | "given-name"
  | "family-name"
  | "email"
  | "current-password"
  | "new-password"
  | "tel"
  | "off"
  | "on";

export interface MobileNumberInputProps<TControl>
  extends HTMLAttributes<HTMLInputElement>,
    Omit<WithLabelProperties, "children"> {
  control: TControl;
  suffix?: string | ReactNode;
  value?: string;
  autoComplete?: AutoCompleteValues;
  required?: boolean;
}

function getMobileNumber(value: number | string) {
  let stringNum = ("" + value || "").replace(/\D/g, "");
  return stringNum ? Math.round(Number.parseFloat(stringNum)).toString() : "";
}

export function MobileNumberInput<TControl = any>({
  name,
  label,
  control,
  autoComplete,
  suffix,
  ...properties
}: MobileNumberInputProps<TControl>): JSX.Element {
  return (
    <WithLabel
      forceActive
      labelPointerEventsNone
      name={name}
      label={label}
      {...properties}
    >
      {({ active, name, handleChange, ...inputProperties }) => {
        if (inputProperties?.inputProps) {
          delete inputProperties.inputProps;
        }
        return (
          <div className={tw("flex", "items-end")}>
            <div className={tw("py-10", "px-8", "text-steel")}>+61</div>
            <div className={tw("flex-1")}>
              <Controller
                name={name}
                control={control as any}
                render={(controller) => {
                  return (
                    <input
                      className={tw(
                        "flex-1",
                        "leading-none",
                        "outline-none",
                        "w-full",
                        "bg-transparent",
                        "text-base",
                        "transition-all",
                        "duration-200",
                        "ease-in-out",
                        "rounded" as any,
                        "px-16",
                        "pl-0",
                        active ? "pt-32" : "pt-20",
                        active ? "pb-12" : "pb-20"
                      )}
                      {...inputProperties}
                      autoComplete={autoComplete}
                      name={controller.name}
                      value={controller.value}
                      onBlur={(e) => {
                        controller.onBlur();
                        properties.onBlur && properties.onBlur(e);
                      }}
                      onKeyPress={blurOnEnter(properties.onKeyPress)}
                      onChange={(e) => {
                        const eventValue = e.target.value;
                        const newValue = isEmpty(eventValue)
                          ? ""
                          : getMobileNumber(eventValue);

                        handleChange(e);
                        controller.onChange(newValue);
                      }}
                    />
                  );
                }}
              />
            </div>
          </div>
        );
      }}
    </WithLabel>
  );
}
