import Slider from "rc-slider";
import React, { FC, FocusEventHandler, ReactNode, useRef } from "react";
import { Control, Controller } from "react-hook-form";

import { tw } from "../../tailwindcss-classnames";
import { B1 } from "../type/Typography";

import { c } from "lib/c";
import { getInt, getNumber } from "lib/get-number";

export interface SliderInputProps {
  control: Control<any>;
  name: string;
  label: string;
  min: number;
  max: number;

  step?: number;

  suffix?: string | ReactNode;
  prefix?: string | ReactNode;
  inputProps?: any;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onChange?: (newValue: number) => void;
  disabled?: boolean;
}
export interface TableSliderInputProps {
  name: string;
  min: number;
  max: number;
  step?: number;
  suffix?: string | ReactNode;
  prefix?: string | ReactNode;
  rowData?: any;
  onChange?: any;
  disabled?: boolean;
}

export const SliderInput: FC<SliderInputProps> = (props) => {
  const {
    name,
    step = 0.1,
    control,
    label,
    prefix,
    suffix,
    min,
    max,
    onChange,
    disabled = false,
  } = props;
  const inputId = name + "-input";
  return (
    <Controller
      name={name}
      control={control}
      render={(controlledProperties) => {
        const onBlur: FocusEventHandler<HTMLInputElement> = (e) => {
          controlledProperties.onBlur();
          if (props.onBlur) {
            props.onBlur(e);
          }
        };
        return (
          <div>
            <div className={tw("flex", "items-center")}>
              <B1
                Component="label"
                extraProps={{ htmlFor: inputId }}
                className={tw("block", "flex-1")}
              >
                {label}
              </B1>
              <div
                className={tw(
                  "rounded-8",
                  "bg-white",
                  "flex",
                  "items-center",
                  "px-8",
                  "border",
                  "border-gray-700"
                )}
                style={{ height: "1.6em" }}
              >
                {prefix && <div>{prefix}</div>}

                <input
                  {...controlledProperties}
                  id={inputId}
                  min={min}
                  max={max}
                  type="number"
                  step={step}
                  inputMode="decimal"
                  className={c(
                    "form-input",
                    tw("flex-1", "bg-transparent", "text-right")
                  )}
                  // onBlur={onBlur}
                  onBlur={(e) => {
                    let value = getNumber(e.target.value);
                    if (Number.isNaN(value)) {
                      value = 0;
                    }
                    value = value <= max ? value : max;
                    value = value >= min ? value : min;
                    controlledProperties.onChange(value);
                    if (onChange) {
                      onChange(value);
                    }
                  }}
                  disabled={disabled}
                  // onInput={(e) => {
                  //   const value = getNumber(e.currentTarget.value);
                  //   e.currentTarget.value = value.toString();
                  // }}
                  onWheel={(e) => {
                    if (e?.currentTarget?.blur) {
                      e?.currentTarget?.blur();
                    }
                  }}
                  style={{ width: "4em" }}
                />

                {suffix && <div>{suffix}</div>}
              </div>
            </div>

            <div className={tw("py-8", "mt-2")}>
              <Slider
                {...controlledProperties}
                min={min}
                max={max}
                onBlur={onBlur}
                onChange={(value: number) => {
                  controlledProperties.onChange(value);
                  if (onChange) {
                    onChange(value);
                  }
                }}
                className={tw("w-full", "text-gold")}
                step={step}
                handleStyle={{
                  backgroundColor: "#C1A246",
                  border: "none",
                  width: 32,
                  height: 32,
                  marginTop: -13,
                }}
                disabled={disabled}
                // handle active state in css. dont ask why
                trackStyle={{ backgroundColor: "#F5EEDB" }}
                railStyle={{ backgroundColor: "#F5EEDB" }}
              />
            </div>
          </div>
        );
      }}
    />
  );
};
export const SliderInputWithoutLabels: FC<SliderInputProps> = (props) => {
  const {
    name,

    control,

    prefix,
    suffix,
    min,
    max,
    onChange,
    disabled = false,
  } = props;
  const inputId = name + "-input";
  return (
    <Controller
      name={name}
      control={control}
      render={(controlledProperties) => {
        return (
          <div className={tw("flex", "items-center")}>
            <div
              className={tw(
                "rounded-8",
                "bg-white",
                "flex",
                "items-center",
                "px-4",
                "container-mid:px-16",

                "border",
                "border-gray-700",
                "flex-1"
              )}
            >
              {prefix && <div>{prefix}</div>}

              <input
                {...controlledProperties}
                id={inputId}
                min={min}
                max={max}
                type="number"
                step="1"
                inputMode="decimal"
                className={c(
                  "form-input",
                  tw("flex-1", "bg-transparent", "text-right", "text-sm")
                )}
                onChange={(e) => {
                  let value = getNumber(e.target.value);
                  if (Number.isNaN(value)) {
                    value = 0;
                  }
                  value = value <= max ? value : max;
                  value = value >= min ? value : min;
                  controlledProperties.onChange(value);
                  if (onChange) {
                    onChange(value);
                  }
                }}
                disabled={disabled}
                style={{ width: "4em" }}
              />

              {suffix && <div>{suffix}</div>}
            </div>
          </div>
        );
      }}
    />
  );
};

export const TableSliderInput: FC<TableSliderInputProps> = (props) => {
  const {
    name,
    prefix,
    suffix,
    min,
    max,
    onChange,
    rowData,
    disabled = false,
  } = props;
  const inputId = rowData.id + name;
  const inputRef = useRef(null);

  return (
    <div className={tw("flex", "items-center")}>
      <div
        className={tw(
          "rounded-8",
          "bg-white",
          "flex",
          "items-center",
          "px-4",
          "container-mid:px-16",
          "border",
          "border-gray-700",
          "flex-1"
        )}
      >
        {prefix && <div>{prefix}</div>}

        <input
          id={inputId}
          min={min}
          max={max}
          type="number"
          step="1"
          inputMode="decimal"
          className={c(
            "form-input",
            tw("flex-1", "bg-transparent", "text-right", "text-sm")
          )}
          onChange={(e) => {
            let value = getInt(e.target.value);
            if (Number.isNaN(value)) {
              value = 0;
            }
            value = value <= max ? value : max;
            value = value >= min ? value : min;
            if (inputRef) inputRef.current.value = value;
            if (onChange) {
              onChange(name, value, rowData);
            }
          }}
          ref={inputRef}
          defaultValue={rowData[name]}
          disabled={disabled}
          style={{ width: "4em" }}
        />

        {suffix && <div>{suffix}</div>}
      </div>
    </div>
  );
};
