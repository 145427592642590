import { Option } from "./Option";

import { LivingSituation } from "graphql/generated";

export const livingSituationOptions: Option<LivingSituation>[] = [
  {
    label: "Living with Family",
    value: LivingSituation.LivingWithFamily,
  },
  {
    label: "Renting",
    value: LivingSituation.Renting,
  },
  {
    label: "Own Home",
    value: LivingSituation.OwnHome,
  },
];
