import { first } from "./first";

const currencyFormatter = new Intl.NumberFormat("en-AU", {
  style: "currency",
  currency: "AUD",
});

export const formatCurrency = (value: number): string =>
  value % 1 === 0
    ? first(currencyFormatter.format(value).split("."))!
    : currencyFormatter.format(value);
