import Cleave from "cleave.js/react";
import { isEmpty } from "lodash";
import { HTMLAttributes, ReactNode } from "react";
import { Controller } from "react-hook-form";

import { tw } from "../../tailwindcss-classnames";

import { WithLabel, WithLabelProps as WithLabelProperties } from "./WithLabel";

import { blurOnEnter } from "lib/blurOnEnter";
import { getNumber } from "lib/get-number";

export interface CurrencyInputProps<TControl>
  extends HTMLAttributes<HTMLInputElement>,
    Omit<WithLabelProperties, "children"> {
  control: TControl;
  suffix?: string | ReactNode;
  value?: string;
  required?: boolean;
}

export interface TableCurrencyProps {
  onHandleChange: any;
  value?: any;
  rowData?: any;
  name?: string;
}

export function CurrencyInput<TControl = any>({
  name,
  label,
  control,
  suffix,
  ...properties
}: CurrencyInputProps<TControl>): JSX.Element {
  return (
    <WithLabel
      labelPointerEventsNone
      forceActive
      name={name}
      label={label}
      {...properties}
    >
      {({ active, name, handleChange, ...inputProperties }) => {
        if (inputProperties?.inputProps) {
          delete inputProperties.inputProps;
        }
        return (
          <div className={tw("flex", "items-end")}>
            <div className={tw("flex-1")}>
              <Controller
                name={name}
                control={control as any}
                render={(controller) => {
                  return (
                    <Cleave
                      className={tw(
                        "form-input",
                        "leading-none",
                        "outline-none",
                        "w-full",
                        "bg-transparent",
                        "text-base",
                        "transition-all",
                        "duration-200",
                        "ease-in-out",
                        "rounded" as any,
                        { "text-disabledTextGray": properties.disabled },
                        "px-16",
                        active ? "pt-32" : "pt-20",
                        active ? "pb-12" : "pb-20"
                      )}
                      options={{
                        prefix: "$",
                        numeral: true,
                        numeralThousandsGroupStyle: "thousand",
                      }}
                      {...inputProperties}
                      name={controller.name}
                      value={controller.value}
                      onBlur={(e) => {
                        controller.onBlur();
                        properties.onBlur && properties.onBlur(e);
                      }}
                      onKeyPress={blurOnEnter(properties.onKeyPress)}
                      onChange={(e) => {
                        const eventValue = e.target.value;
                        const newValue = isEmpty(eventValue)
                          ? "0"
                          : getNumber(eventValue || "0") || 0;

                        handleChange(e);
                        controller.onChange(newValue);
                      }}
                    />
                  );
                }}
              />
            </div>
            {suffix && (
              <div
                className={tw(
                  "py-10",
                  "px-8",
                  properties.disabled ? "text-disabledTextGray" : "text-steel"
                )}
              >
                {suffix}
              </div>
            )}
          </div>
        );
      }}
    </WithLabel>
  );
}
export function CurrencyInputWithoutLabel<TControl = any>({
  name,
  label,
  control,
  suffix,
  ...properties
}: CurrencyInputProps<TControl>): JSX.Element {
  return (
    <WithLabel
      labelPointerEventsNone
      forceActive
      name={name}
      label={label}
      {...properties}
    >
      {({ active, name, handleChange, ...inputProperties }) => {
        if (inputProperties?.inputProps) {
          delete inputProperties.inputProps;
        }
        return (
          <div className={tw("flex", "items-end")}>
            <div className={tw("flex-1") + " max-w-xxs"}>
              <Controller
                name={name}
                control={control as any}
                render={(controller) => {
                  return (
                    <Cleave
                      className={
                        tw(
                          "form-input",
                          "leading-none",
                          "outline-none",
                          "w-full",
                          "bg-transparent",
                          "text-sm",
                          "transition-all",
                          "duration-200",
                          "ease-in-out",
                          "rounded" as any,
                          { "text-disabledTextGray": properties.disabled },
                          "px-4",
                          "container-mid:px-16",
                          "py-10"
                        ) + " min-w-max"
                      }
                      options={{
                        prefix: "$",
                        numeral: true,
                        numeralThousandsGroupStyle: "thousand",
                      }}
                      {...inputProperties}
                      name={controller.name}
                      value={controller.value}
                      onBlur={(e) => {
                        controller.onBlur();
                        properties.onBlur && properties.onBlur(e);
                      }}
                      onKeyPress={blurOnEnter(properties.onKeyPress)}
                      onChange={(e) => {
                        const eventValue = e.target.value;
                        const newValue = isEmpty(eventValue)
                          ? "0"
                          : getNumber(eventValue || "0") || 0;

                        handleChange(e);
                        controller.onChange(newValue);
                      }}
                    />
                  );
                }}
              />
            </div>
            {suffix && (
              <div
                className={tw(
                  "py-10",
                  "px-8",
                  properties.disabled ? "text-disabledTextGray" : "text-steel"
                )}
              >
                {suffix}
              </div>
            )}
          </div>
        );
      }}
    </WithLabel>
  );
}

export function TableCurrencyInput({
  onHandleChange,
  value,
  rowData,
  name,
}: TableCurrencyProps) {
  const onChangeHandle = (e) => {
    const eventValue = e.target.value;
    const newValue = isEmpty(eventValue)
      ? "0"
      : getNumber(eventValue || "0") || 0;
    return newValue;
  };
  return (
    <div className={tw("flex", "items-end")}>
      <div className={tw("flex-1") + " max-w-xxs"}>
        <Cleave
          className={
            tw(
              "form-input",
              "leading-none",
              "w-full",
              "text-sm",
              "transition-all",
              "duration-200",
              "ease-in-out",
              "rounded" as any,
              "px-4",
              "container-mid:px-16",
              "py-10",
              "border",

              "bg-transparent",
              "hover:shadow-outline",
              "focus:border-gray-900",
              "focus:outline-none",
              "border-gray-900",
              "rounded-8"
            ) + " min-w-max"
          }
          options={{
            prefix: "$",
            numeral: true,
            numeralThousandsGroupStyle: "thousand",
          }}
          name={name}
          value={value}
          onBlur={(e) => {
            onHandleChange(name, onChangeHandle(e), rowData);
          }}
        />
      </div>
    </div>
  );
}
