import React, { FC, HTMLAttributes, ReactNode } from "react";

import { tw } from "../../tailwindcss-classnames";
import { B2 } from "../type/Typography";

import { WithLabel, WithLabelProps } from "./WithLabel";

import { c } from "lib/c";

interface CheckboxProps extends HTMLAttributes<HTMLInputElement> {
  name?: string;
  className?: string;
  checked?: boolean;
  inputRef?: any;
}

const checkboxClass = tw(
  "form-checkbox",
  "text-gold",
  "border-steel" as any,
  "border-1" as any,
  "rounded-4"
);

export const Checkbox: FC<CheckboxProps> = ({
  inputRef,
  className,
  ...props
}) => {
  return (
    <input
      type="checkbox"
      {...props}
      className={c(checkboxClass, className)}
      ref={inputRef}
    />
  );
};

export const CheckboxWithLabel: FC<
  CheckboxProps & { label: string | ReactNode }
> = ({ inputRef, label, className, ...props }) => {
  const inputId = props.name + "-input";
  return (
    <div className={tw("flex", "space-x-8")}>
      <div>
        <input
          type={"checkbox"}
          {...props}
          className={c(checkboxClass, className)}
          ref={inputRef}
        />
      </div>
      <label
        htmlFor={inputId}
        id={props.name + "-label"}
        className={tw("py-4", "text-sm")}
      >
        {label}
      </label>
    </div>
  );
};
