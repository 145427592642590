import { Option } from "./Option";

import { BillFrequency } from "graphql/generated";

export const billFrequencyOptions: Option<BillFrequency>[] = [
  { label: "Weekly", value: BillFrequency.Weekly },
  { label: "Fortnightly", value: BillFrequency.Fortnightly },
  { label: "Monthly", value: BillFrequency.Monthly },
  { label: "Quarterly", value: BillFrequency.Quarterly },
  { label: "Yearly", value: BillFrequency.Yearly },
];
