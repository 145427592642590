import { Option } from "./Option";

import { PersonalityType } from "graphql/generated";

export const personalityTypeOptions: Option<PersonalityType>[] = [
  {
    label: "Tight-Arse",
    value: PersonalityType.TightArse,
    title:
      "Hates spending money on anything. \n$50 in their wallet could stay there for weeks.",
  },
  {
    label: "Saver",
    value: PersonalityType.Saver,
    title:
      "Good at sticking to savings plan and \nappreciates the value of buying assets to plan for the future.",
  },
  {
    label: "Balanced",
    value: PersonalityType.Balanced,
    title:
      "Enjoy spending, but understand the value of saving. \nOccasionally spend savings on big ticket items.",
  },
  {
    label: "Spender",
    value: PersonalityType.Spender,
    title:
      "Enjoy spending the money they have \nand would prefer to spend more if more is available. \nSavings tend to be spent on big ticket items like holidays.",
  },
  {
    label: "Shopaholic",
    value: PersonalityType.Shopaholic,
    title:
      "Addicted to shopping, loves credit cards, afterpay and \nhas lots of unused items at home.",
  },
];
