import React, { HTMLAttributes } from "react";

import { tw } from "../../tailwindcss-classnames";

import { Option } from "./formData/Option";
import { WithLabel, WithLabelProps } from "./WithLabel";

export interface SelectProps<T>
  extends HTMLAttributes<HTMLSelectElement>,
    Omit<WithLabelProps, "children"> {
  options: Option<T>[];
  value?: T | Option<T>;
}

export interface TableSelectProps {
  onHandleChange: any;
  options?: any;
  rowData?: any;
  name?: string;
}

export function Select<T extends string | number>({
  options,
  inputRef,
  ...rest
}: SelectProps<T>) {
  return (
    <WithLabel {...rest}>
      {({ required, handleChange, name, inputProps }) => (
        <select
          {...inputProps}
          style={{ paddingTop: 30, paddingBottom: 10 }}
          className={tw("form-select", "w-full", "bg-transparent", "px-16")}
          onChange={(e) => {
            handleChange(e);
            if (inputProps.onChange) {
              inputProps.onChange(e);
            }
          }}
          id={[name, "input"].join("-")}
          name={name}
          required={required}
          ref={inputRef}
          value={getValue(inputProps.value)}
        >
          {options.map(({ label, value, title }) => (
            <option
              key={getValue(value as any)}
              value={getValue(value as any)}
              title={title}
            >
              {label}
            </option>
          ))}
        </select>
      )}
    </WithLabel>
  );
}

function getValue(
  o?: Option<string | number> | string
): undefined | string | number {
  if (typeof o === "string" || typeof o === "number") return o;
  if (!!o && typeof o === "object" && "value" in o) return o.value;
}

export function WithoutLabelSelect<T extends string | number>({
  options,
  inputRef,
  ...rest
}: SelectProps<T>) {
  return (
    <WithLabel {...rest}>
      {({ required, handleChange, name, inputProps }) => (
        <select
          {...inputProps}
          className={tw(
            "form-select",
            "w-full",
            "bg-transparent",
            "px-4",
            "container-mid:px-16",
            "text-sm",
            "py-8"
          )}
          onChange={(e) => {
            handleChange(e);
            if (inputProps.onChange) {
              inputProps.onChange(e);
            }
          }}
          id={[name, "input"].join("-")}
          name={name}
          required={required}
          ref={inputRef}
          value={getValue(inputProps.value)}
        >
          {options.map(({ label, value, title }) => (
            <option
              key={getValue(value as any)}
              value={getValue(value as any)}
              title={title}
            >
              {label}
            </option>
          ))}
        </select>
      )}
    </WithLabel>
  );
}

export function TableSelect({
  onHandleChange,
  options,
  rowData,
  name,
}: TableSelectProps) {
  return (
    <select
      className={tw(
        "form-select",
        "w-full",
        "bg-transparent",
        "px-4",
        "container-mid:px-16",
        "text-sm",
        "py-8"
      )}
      onChange={(e) => {
        onHandleChange(name, e.target.value, rowData);
      }}
      id={[name, "input"].join("-")}
      name={rowData.id + name}
      value={rowData[name]}
    >
      {options.map(({ label, value, title }) => (
        <option key={value + rowData.id} value={value} title={title}>
          {label}
        </option>
      ))}
    </select>
  );
}
