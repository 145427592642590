import { Option } from "./Option";

import { RelationshipStatus } from "graphql/generated";

export const relationshipStatusOptions: Option<RelationshipStatus>[] = [
  {
    label: "Me",
    value: RelationshipStatus.Single,
  },
  {
    label: "Me and my partner",
    value: RelationshipStatus.Couple,
  },
];
