import { FC, HTMLAttributes, useCallback, useRef } from "react";
import { FieldError } from "react-hook-form";

import { tw } from "../../tailwindcss-classnames";

interface SteppedNumberInputProps extends HTMLAttributes<HTMLInputElement> {
  name: string;
  label: string;
  error?: Error | FieldError;
  inputRef?: any;
  value?: any;
  required?: boolean;
  min?: number;
  max?: number;
  setValue: (n: number) => void;
}

export const SteppedNumberInput: FC<SteppedNumberInputProps> = ({
  setValue,
  inputRef,
  label,
  min = 0,
  max,
  name,
  ...rest
}) => {
  const containerRef = useRef(null);

  const decrease = useCallback(() => {
    if (containerRef.current?.firstChild != undefined) {
      const parsed =
        Number.parseInt(containerRef.current?.firstChild.value, 10) || 0;
      const clamp = min != undefined ? min : 0;

      setValue(Math.max(clamp, parsed - 1));
    }
  }, [containerRef.current?.firstChild, setValue]);

  const increase = useCallback(() => {
    if (containerRef.current?.firstChild != undefined) {
      const parsed =
        Number.parseInt(containerRef.current?.firstChild.value, 10) || 0;
      const clamp = max != undefined ? max : Number.POSITIVE_INFINITY;

      setValue(Math.min(clamp, parsed + 1));
    }
  }, [containerRef.current?.firstChild, setValue]);

  const buttonClass = tw(
    "bg-sand",
    "appearance-none",
    "focus:outline-none",
    "m-8",
    "flex-col",
    "rounded-full",
    "w-48",
    "h-48",
    "flex",
    "justify-center",
    "items-center",
    "text-center"
  );

  return (
    <div className={tw("flex", "items-center")}>
      <div className={tw("flex-1")}>{label}</div>
      <div>
        <button type="button" onClick={decrease} className={buttonClass}>
          <img src={"/Icon_Minus.svg"} />
        </button>
      </div>
      <div ref={containerRef}>
        <input
          className={tw(
            "form-input",
            "border-1" as any,
            "rounded-4",
            "border-ink" as any
          )}
          name={name}
          type="number"
          min={min}
          max={max}
          ref={inputRef}
          {...rest}
          style={{ maxWidth: "56px", minHeight: 46 }}
        />
      </div>
      <div>
        <button type="button" onClick={increase} className={buttonClass}>
          <img src={"/Icon_Plus.svg"} />
        </button>
      </div>
    </div>
  );
};
