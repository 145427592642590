import { Option } from "./Option";

import { IncomeType } from "graphql/generated";

export const incomeTypeOptions: Option<IncomeType>[] = [
  { label: `${IncomeType.Net} (After Tax) `, value: IncomeType.Net },
  {
    label: `${IncomeType.Gross} (Before Tax Excluding Super)`,
    value: IncomeType.Gross,
  },
];
