import { Option } from "./Option";

import { GoalKey, GoalTerm, MortgageType } from "graphql/generated";

export const GoalKeyLabel: Record<GoalKey, string> = {
  Big_Things: "Big Things",
  Celebrations: "Celebrations",
  Holidays: "Holidays",
  Investment_Property: "Investment Property",
  Income_Career: "Income/Career",
  Own_Home: "Own Home",
  Relationship_and_Family: "Relationship and Family",
};

export const goalKeyOptions: Option<GoalKey>[] = Object.entries(
  GoalKeyLabel
).map(([value, label]) => ({ value: value as GoalKey, label }));

export function goalTermLabel(term: GoalTerm) {
  return term.replace("Term", "") + " Term";
}

export const goalTermOptions: Option<GoalTerm>[] = [
  GoalTerm.ShortTerm,
  GoalTerm.MediumTerm,
  GoalTerm.LongTerm,
].map((value) => ({ value, label: goalTermLabel(value) }));

export const MortageLoanTypes = [
  {
    value: MortgageType.InterestOnly,
    label: "Interest Only",
  },
  {
    value: MortgageType.PrincipalAndInterest,
    label: "Principal & Interest",
  },
];
