import React, { FC, HTMLAttributes } from "react";

import { tw } from "../../tailwindcss-classnames";

import { WithLabel, WithLabelProps } from "./WithLabel";

type AutoCompleteValues =
  | "given-name"
  | "family-name"
  | "email"
  | "current-password"
  | "new-password"
  | "tel"
  | "off"
  | "on";

export interface InputProps
  extends HTMLAttributes<HTMLInputElement>,
    Omit<WithLabelProps, "children"> {
  type?: "text" | "number" | "password" | "email";
  autoComplete?: AutoCompleteValues;
  disabled?: boolean;
  value?: string;
  suffix?: string;
}

export const Input: FC<InputProps> = ({
  suffix,
  autoComplete,
  inputRef,
  type = "text",
  ...rest
}) => {
  return (
    <WithLabel {...rest}>
      {({ required, handleChange, active, name, inputProps }) => (
        <div className={tw("flex", "items-end", "h-full", "space-x-16")}>
          <input
            {...inputProps}
            className={tw(
              "flex-1",
              "leading-none",
              "outline-none",
              "w-full",
              "bg-transparent",
              "text-base",
              "transition-all",
              "duration-200",
              "ease-in-out",
              "rounded" as any,
              "px-16",
              active ? "pt-32" : "pt-20",
              active ? "pb-12" : "pb-20"
            )}
            onChange={handleChange}
            autoComplete={autoComplete}
            id={[name, "input"].join("-")}
            name={name}
            required={required}
            type={type}
            ref={inputRef}
            onWheel={(e) => {
              if (e?.currentTarget?.blur) {
                e?.currentTarget?.blur();
              }
            }}
          />
          {suffix && (
            <div
              className={tw(
                "py-10",
                "px-8",
                inputProps.disabled ? "text-disabledTextGray" : "text-steel"
              )}
            >
              {suffix}
            </div>
          )}
        </div>
      )}
    </WithLabel>
  );
};
