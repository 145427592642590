import React, {
  ChangeEventHandler,
  FC,
  HTMLAttributes,
  ReactNode,
  useCallback,
} from "react";

import { tw } from "../../tailwindcss-classnames";

import styles from "./Toggle.module.css";

import { c } from "lib/c";

interface ToggleProps {
  label?: string | ReactNode;
  name: string;
  value?: boolean;
  onChange?: (newValue: boolean) => void;

  wrapperProps?: Partial<HTMLAttributes<HTMLDivElement>>;
}

interface ToggleCheckboxProps {
  name?: string;
  className?: string;
  checked?: boolean;
  inputRef?: any;
  label?: string | ReactNode;
}

export const Toggle: FC<ToggleProps> = (props) => {
  const handleOnChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      const input = e.currentTarget;
      const checked = !!input.checked;
      props.onChange(checked);
    },
    [props.onChange]
  );

  return (
    <>
      <div
        className="flex items-center justify-center w-full"
        {...(props.wrapperProps ?? {})}
      >
        <label
          htmlFor={props.name + "-input"}
          id={props.name + "-label"}
          className={tw(
            "flex",
            "items-center",
            "cursor-pointer",
            "w-full",
            "space-x-8"
          )}
        >
          {props.label && <div className={tw("flex-1")}>{props.label}</div>}
          <div className={tw("relative")}>
            <input
              id={props.name + "-input"}
              name={props.name}
              type="checkbox"
              className={tw("hidden")}
              checked={props.value}
              onChange={handleOnChange}
            />
            <div
              className={tw(
                "w-32",
                "h-16",
                "bg-sand",
                "rounded-full",
                "shadow-inner"
              )}
            />
            <div
              className={c(
                styles.dot,
                tw(
                  "absolute",
                  "w-16",
                  "h-16",
                  "bg-steel",
                  "rounded-full",
                  "shadow",
                  "inset-y-0",
                  "left-0"
                )
              )}
            />
          </div>
        </label>
      </div>
    </>
  );
};

export const ToggleWithLabel: FC<ToggleCheckboxProps> = ({
  inputRef,
  label,
  ...props
}) => {
  return (
    <>
      <div className="flex items-center ">
        <label
          htmlFor={props.name + "-input"}
          id={props.name + "-label"}
          className={"flex items-center cursor-pointer  space-x-16"}
        >
          {label && <div className={tw("flex-1")}>{label}</div>}
          <div className={tw("relative")}>
            <input
              className={tw("hidden")}
              type={"checkbox"}
              {...props}
              ref={inputRef}
            />
            <div
              className={tw(
                "w-48",
                "h-24",
                "bg-sand",
                "rounded-full",
                "shadow-inner"
              )}
            >
              <span className="absolute font-medium text-xs pt-1  left-4 top-2">
                Yes
              </span>
              <span className="absolute font-medium text-xs pt-1  right-4 top-2">
                No
              </span>
            </div>

            <div
              className={c(
                styles.dot,
                tw(
                  "absolute",
                  "w-24",
                  "h-24",
                  "bg-steel",
                  "rounded-full",
                  "shadow",
                  "inset-y-0",
                  "left-0"
                )
              )}
            />
          </div>
        </label>
      </div>
    </>
  );
};
