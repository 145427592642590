import { Option } from "./Option";

import {
  BillFrequency,
  FbhType,
  LmiWaiverType,
  PropertyType,
  PurchaseLoanAmountType,
  StateType,
} from "graphql/generated";

export const StateOptions: Option<StateType>[] = [
  { label: StateType.Nsw, value: StateType.Nsw },
  { label: StateType.Vic, value: StateType.Vic },
  { label: StateType.Qld, value: StateType.Qld },
  { label: StateType.Sa, value: StateType.Sa },
  { label: StateType.Wa, value: StateType.Wa },
  { label: StateType.Nt, value: StateType.Nt },
  { label: StateType.Act, value: StateType.Act },
  { label: StateType.Tas, value: StateType.Tas },
];

export const FbhOptions: Option<FbhType>[] = [
  { label: FbhType.Yes, value: FbhType.Yes },
  { label: FbhType.No, value: FbhType.No },
];

export const PropertyTypeOptions: Option<PropertyType>[] = [
  { label: "Established", value: PropertyType.Established },
  { label: "Off The Plan", value: PropertyType.OffThePlan },
  { label: "House And Land", value: PropertyType.HouseAndLand },
  { label: "Land", value: PropertyType.Land },
];

export const PurchaseLoanTypeOptions: Option<PurchaseLoanAmountType>[] = [
  {
    label: PurchaseLoanAmountType.Percentage,
    value: PurchaseLoanAmountType.Percentage,
  },
  {
    label: PurchaseLoanAmountType.Amount,
    value: PurchaseLoanAmountType.Amount,
  },
];

export const RepaymentFrequencyOption: Option<BillFrequency>[] = [
  {
    label: BillFrequency.Weekly,
    value: BillFrequency.Weekly,
  },
  {
    label: BillFrequency.Fortnightly,
    value: BillFrequency.Fortnightly,
  },
  {
    label: BillFrequency.Monthly,
    value: BillFrequency.Monthly,
  },
];

export enum PlannedPurchaseType {
  Home = "Home",
  Investment = "Investment",
}

export const PlannedPurchaseOption: Option<PlannedPurchaseType>[] = [
  { label: PlannedPurchaseType.Home, value: PlannedPurchaseType.Home },
  {
    label: PlannedPurchaseType.Investment,
    value: PlannedPurchaseType.Investment,
  },
];

export const LmiWaiverOption: Option<LmiWaiverType>[] = [
  { label: LmiWaiverType.None, value: LmiWaiverType.None },
  {
    label: "Medical Professional",
    value: LmiWaiverType.MedicalProfessional,
  },
  {
    label: "Accountant",
    value: LmiWaiverType.Accountant,
  },
  {
    label: "Lawyer",
    value: LmiWaiverType.Lawyer,
  },
  {
    label: "Engineer",
    value: LmiWaiverType.Engineer,
  },
  {
    label: "IT Professional",
    value: LmiWaiverType.ItProfessional,
  },
  {
    label: "Bank Staff",
    value: LmiWaiverType.BankStaff,
  },
  {
    label: "85% no LMI",
    value: LmiWaiverType.NoLmi,
  },
  {
    label: "First Home Guarantee",
    value: LmiWaiverType.HomeGuarantee,
  },
];

export function getLmiWaiverOptionsWithChecks(showHomeGuarantee) {
  if (showHomeGuarantee) {
    {
      return LmiWaiverOption;
    }
  }

  return LmiWaiverOption.filter((i) => i.value !== LmiWaiverType.HomeGuarantee);
}
