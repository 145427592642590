import { Option } from "./Option";

import { KeepVsSell, UseEquity } from "graphql/generated";

export const KeepVsSellOptions: Option<KeepVsSell>[] = [
  { label: KeepVsSell.Keep, value: KeepVsSell.Keep },
  { label: KeepVsSell.Sell, value: KeepVsSell.Sell },
];

export const UseEquityOption: Option<UseEquity>[] = [
  { label: UseEquity.Yes, value: UseEquity.Yes },
  { label: UseEquity.No, value: UseEquity.No },
];
