export const FORM_CONSTANT = {
  SIGN_UP: "signup",
  RESET_PASSWORD: "resetpassword",
};

export const INITIAL_CONSULT_CALENDLY_LINK =
  "https://calendly.com/maxatgoldeneggs/initial-consult";

export const FULL_CONSULT_CALENDLY_LINK =
  "https://calendly.com/maxatgoldeneggs/full-consultation";
